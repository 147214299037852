@use "../designSystem/common/sassVars" as *;

.navbar__searchOld {
	@media (min-width: $navbar-breakpoint-min) {
		position: relative;
	}

	form {
		display: none;
	}

	.navbar--searchOn & {
		form {
			display: grid;
			grid-template-columns: 1fr auto;
			position: absolute;
			top: 0;
			bottom: 0;
			background-color: #fff;
			column-gap: 15px;
			align-items: baseline;
			z-index: 1;

			@media (min-width: $navbar-breakpoint-min) {
				top: -11px;
				right: 0;
				width: 300px;
			}

			@media (max-width: $navbar-breakpoint-max) {
				width: calc(100% - 40px);
				top: 0;
			}

			input {
				margin: 0;
				border: 0;
				border-bottom: 1px solid #000;
				background: url('../../../web/img/svg/navbar-search.svg') no-repeat;
				padding: 0 30px 0 0;
				height: 44px;

				@media (min-width: $navbar-breakpoint-min) {
					background-size: 22px;
					background-position: center right 2px;
				}

				@media (max-width: $navbar-breakpoint-max) {
					background-size: 28px;
					background-position: center right;
					font-size: 16px;
				}

			}

		}
	}
}


.navbar__features {
	@media (max-width: $navbar-breakpoint-max) {
		.navbar--searchOn & {
			overflow-x: hidden;

			.navbar__feature:not(.navbar__searchOld) {
				display: none;
			}
		}
	}
}
